import React from 'react';
import BlueskyFeed from '../components/BlueskyFeed';
import './Home.css';
import homeData from './../data/homeData.json';
import TextFormatter from './../components/TextFormatter';
import ImageGallery from '../components/ImageGallery';

function Home() {
    return (
        <div className="home-container">
            <div className="welcome-message">
                <h2> Home</h2>
                <p className="home-description">
                    <TextFormatter text={homeData.description} />
                </p>
            </div>
            <div className='youtubePlayer'>
                <iframe
                    className="youtube-iframe"
                    width="960"
                    height="540"
                    src="https://www.youtube.com/embed/7Vav9DcPKu0"
                    title="Demoreel"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            </div>
            <div className='bluesky-feed'>
                <h2>Latest Posts from BlueSky</h2>
                <div className='bluesky-feed-content'>
                    <BlueskyFeed />
                </div>
            </div>
            <ImageGallery images={homeData.images} />
        </div>
    );
}

export default Home;
