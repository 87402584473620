import React, { useState } from 'react';
import './HireMe.css';
import hireMeData from './../data/hireMeData.json'; // Use import instead of fetch
import TextFormatter from '../components/TextFormatter';

function HireMe() {
    const [formData, setFormData] = useState({ name: '', message: '', jobType: '', website: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, message, jobType, website } = formData;

        const body = `Name: ${name}\nJob Type: ${jobType}\nWebsite: ${website}\n\nMessage: ${message}`;
        const mailtoLink = `mailto:avizuradnb@hotmail.com?subject=${jobType}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;

        setFormData({ name: '', message: '', jobType: 'Other', website: '' });
    };

    return (
        <div className="hire-me-container">
            <h2>Contact</h2>
            <p className="collaboration-text">
                <TextFormatter text={hireMeData.description} />
            </p>
            <form onSubmit={handleSubmit} className="contact-form">
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="form-input"
                />
                <input
                    type="text"
                    name="website"
                    placeholder="Your Website (optional)"
                    value={formData.website}
                    onChange={handleChange}
                    className="form-input"
                />
                <select
                    name="jobType"
                    value={formData.jobType}
                    onChange={handleChange}
                    required
                    className="form-select"
                >
                    <option value="Music Composition">Music Composition</option>
                    <option value="Sound Design">Sound Design</option>
                    <option value="Music Production">Music Production</option>
                    <option value="Animation">Animation</option>
                    <option value="Artwork Commission">Artwork Commission</option>
                    <option value="Other">Other</option>
                </select>
                <textarea
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="form-textarea"
                ></textarea>
                <button type="submit" className="submit-button">Send</button>
            </form>
        </div>
    );
}

export default HireMe;
