import supportData from './../data/supportData.json';
import TextFormatter from '../components/TextFormatter';
import ImageGallery from '../components/ImageGallery';

function Support() {
    return (
        <div>
            <h2>Support</h2>
            <section>
                <TextFormatter text={supportData.description} />
                <ImageGallery images={supportData.images} />
            </section>
        </div>
    );
}

export default Support;
