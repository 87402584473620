import React, { useEffect, useState } from 'react';
import royaltyFreeData from './../data/royaltyFreeData.json';
import './Freebies.css';
import TextFormatter from '../components/TextFormatter';
import ImageGallery from '../components/ImageGallery';

const Freebies = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        // Set the data from JSON to state
        setData(royaltyFreeData);
    }, []);

    return (
        <div className="freebies-container">
            <h2>Royalty-Free</h2>
            <section>
                <p><TextFormatter text={data.introduction} /></p>
            </section>
            <section>
                <h3>Usage Guidelines</h3>
                <ul className="freebies-guidelines-list">
                    {data.guidelines && data.guidelines.map((guideline, index) => (
                        <li key={index}>{guideline}</li>
                    ))}
                </ul>
            </section>
            <section>
                <h3>Free for Non-Commercial Use</h3>

                <div className="free-tracks">
                    {data.free && data.free.length > 0 ? (
                        data.free.map((pack, index) => (
                            <div key={index} className="music-pack-item">
                                <div className="thumbnail-container">
                                    {pack.thumbnail ? (
                                        <a href={pack.downloadLink}><img
                                            src={pack.thumbnail}
                                            alt={pack.title}
                                            className="thumbnail"
                                        /></a>
                                    ) : (
                                        <div className="thumbnail-placeholder">No Thumbnail Available</div>
                                    )}
                                </div>
                                <div className="pack-info">
                                    <a href={pack.downloadLink}><h4 className="pack-title">{pack.title}</h4></a>
                                    <p className="pack-description"><TextFormatter text={pack.description} /></p>
                                </div>
                                <a href={pack.downloadLink} target="_blank" rel="noopener noreferrer" className="download-button">Download</a>
                            </div>
                        ))
                    ) : (
                        <p>Coming soon</p>
                    )}
                    <TextFormatter text={'For more free music, check out my <a href="https://avizurang.newgrounds.com/audio">Newgrounds</a> page.'} />
                </div>

            </section>
            <section>
                <h3>Paid for Commercial Use</h3>
                <div className="paid-tracks">
                    {data.paid && data.paid.length > 0 ? (
                        data.paid.map((pack, index) => (
                            <div key={index} className="music-pack-item">
                                <div className="thumbnail-container">
                                    {pack.thumbnail ? (
                                        <a href={pack.downloadLink}><img
                                            src={pack.thumbnail}
                                            alt={pack.title}
                                            className="thumbnail"
                                        /></a>
                                    ) : (
                                        <div className="thumbnail-placeholder">No Thumbnail Available</div>
                                    )}
                                </div>
                                <div className="pack-info">
                                    <h4>{pack.title}</h4>
                                    <p className="pack-description"><TextFormatter text={pack.description} /></p>
                                </div>
                                <a href={pack.downloadLink} target="_blank" rel="noopener noreferrer" className="download-button">Download</a>
                            </div>
                        ))
                    ) : (
                        <p>Coming soon</p>
                    )}
                </div>
            </section>
            <ImageGallery images={royaltyFreeData.images} />
        </div>
    );
};

export default Freebies;
