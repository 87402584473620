// TextFormatter.js
import React from 'react';

const TextFormatter = ({ text = "" }) => {
    const renderContent = (inputText) => {
        if (typeof inputText !== 'string') return null;

        return inputText.split(/(<br\s*\/?>)/).map((part, index) => {
            if (part.match(/<br\s*\/?>/)) {
                return <br key={index} />;
            }

            const regex = /<\/?(strong|i|u|a)([^>]*)>(.*?)<\/\1>/g;
            const formattedParts = [];
            let lastIndex = 0;

            part.replace(regex, (match, tagName, attributes = "", content = "", offset) => {
                if (lastIndex < offset) {
                    const textBeforeTag = part.substring(lastIndex, offset);
                    formattedParts.push(textBeforeTag);
                }

                if (tagName === 'strong') {
                    formattedParts.push(<strong key={lastIndex}>{content}</strong>);
                } else if (tagName === 'i') {
                    formattedParts.push(<em key={lastIndex}>{content}</em>);
                } else if (tagName === 'u') {
                    formattedParts.push(<u key={lastIndex}>{content}</u>);
                } else if (tagName === 'a') {
                    const hrefMatch = attributes.match(/href="([^"]+)"/);
                    const href = hrefMatch ? hrefMatch[1] : '#';
                    formattedParts.push(
                        <a key={lastIndex} href={href} target="_blank" rel="noopener noreferrer">
                            {content}
                        </a>
                    );
                }

                lastIndex = offset + match.length;
                return match;
            });

            if (lastIndex < part.length) {
                const textAfterLastTag = part.substring(lastIndex);
                formattedParts.push(textAfterLastTag);
            }

            return (
                <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
                    {formattedParts}
                </span>
            );
        });
    };

    return <span>{renderContent(text)}</span>;
};

export default TextFormatter;
