import React, { useState } from 'react';
import musicData from './../data/musicData.json';
import MusicModal from '../components/MusicModal.js';
import ImageGallery from '../components/ImageGallery.js';
import './Music.css';
import PlaceholderImg from '../img/blue_cd.jpg';

function Music() {
    const [activeSectionIndex, setActiveSectionIndex] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const closeModal = () => {
        setSelectedItem(null);
    };

    const placeholderImage = PlaceholderImg;

    return (
        <div>
            <section id="music-demos">
                <h2>Music</h2>
                <div className="soundcloud-players">
                    <div className="player-container">
                        <h4>Soundtracks</h4>
                        <iframe
                            width="100%"
                            height="450"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1898405351&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
                            title="Soundcloud Soundtracks"
                        />
                    </div>
                    <div className="player-container">
                        <h4>Electronic</h4>
                        <iframe
                            width="100%"
                            height="450"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1898405259&color=%2373a5f8&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
                            title="Soundcloud Electronic"
                        />
                    </div>
                </div>
            </section >
            <section id="music-discography">
                <h3>Discography</h3>
                <span>Click on a section to see its contents.</span>
                {
                    musicData.sections.map((section, sectionIndex) => (
                        <section key={sectionIndex}>
                            <div
                                className="section-header"
                                onClick={() => setActiveSectionIndex(activeSectionIndex === sectionIndex ? null : sectionIndex)}
                            >
                                <span className="section-title left">{section.name}</span>
                            </div>
                            {activeSectionIndex === sectionIndex && (
                                <div className="grid">
                                    {section.items.map((item, itemIndex) => (
                                        <div key={itemIndex} className="music-item">
                                            <img
                                                src={item.coverImage || placeholderImage}
                                                alt={item.title}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = placeholderImage;
                                                }}
                                                className="album-image"
                                                onClick={() => handleItemClick(item)}
                                            />
                                            <h5 onClick={() => handleItemClick(item)} className="item-title music-item-title">{item.title}</h5>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </section>
                    ))
                }
                <ImageGallery images={musicData.images} />
            </section>
            <MusicModal show={!!selectedItem} onClose={closeModal} item={selectedItem} />
        </div >
    );
}

export default Music;
