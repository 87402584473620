import React, { useState, useEffect } from 'react';
import animationData from './../data/animationData.json';
import AnimationModal from './../components/AnimationModal';
import './Animations.css';
import ImageGallery from '../components/ImageGallery';

function Animations() {
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [activeVideo, setActiveVideo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (video) => {
        setActiveVideo(video);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setActiveVideo(null);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isModalOpen]);

    const placeholderImage = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='180' height='180'><rect width='100%' height='100%' fill='%23ccc'/><text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='%23666' font-size='14' font-weight='bold'>No Image</text></svg>";

    return (
        <div>
            <h2>Animations</h2>
            {animationData.sections.map((section, sectionIndex) => (
                <section key={sectionIndex}>
                    <div
                        className="section-header"
                        onClick={() => setActiveSectionIndex(activeSectionIndex === sectionIndex ? null : sectionIndex)}
                    >
                        <span className="section-title left">{section.name}</span>
                    </div>

                    {activeSectionIndex === sectionIndex && (
                        <div>
                            <p className="section-description">{section.description}</p>
                            <div className="grid">
                                {section.videos.map((video, videoIndex) => (
                                    <div
                                        key={videoIndex}
                                        className="video-thumbnail"
                                        onClick={() => openModal(video)}
                                    >
                                        <img
                                            src={video.thumbnail || placeholderImage}
                                            alt={video.title}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = placeholderImage;
                                            }}
                                            className="thumbnail-image large"
                                        />
                                        <h5 className="item-title animation-item-title">{video.title}</h5>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </section>
            ))}
            <ImageGallery images={animationData.images} />
            <AnimationModal isOpen={isModalOpen} activeVideo={activeVideo} closeModal={closeModal} />
        </div>
    );
}

export default Animations;
