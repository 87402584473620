import React, { useEffect, useState } from 'react';
import './BlueskyFeed.css';

function BlueSkyFeed() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchRSS = async () => {
            try {
                const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://bsky.app/profile/avizura.bsky.social/rss');
                const data = await response.json();

                if (data.items) {
                    setPosts(data.items.slice(0, 5));
                } else {
                    console.warn("No items found in feed.");
                }
            } catch (error) {
                console.error('Error fetching RSS feed:', error);
            }
        };
        fetchRSS();
    }, []);


    return (
        <div>
            <ul>
                {posts.map((post, index) => (
                    <li key={index} className="post-item">
                        <p>{convertLinks(post.description)}</p>
                        <div className="post-footer">
                            <small className="post-date">
                                {post.pubDate}
                            </small>
                            {post.link ? (
                                <a
                                    className="post-link"
                                    href={post.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Visit Post
                                </a>
                            ) : (
                                <small>Link unavailable</small>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );

}

// Function to convert plain URLs in text to React components
function convertLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
        if (urlRegex.test(part)) {
            return (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                    [link]
                </a>
            );
        }
        return part;
    });
}

export default BlueSkyFeed;
