import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Music from './pages/Music';
import Animations from './pages/Animations';
import Games from './pages/Games';
import Artwork from './pages/Artwork';
import Freebies from './pages/Freebies';
import HireMe from './pages/HireMe';
import Support from './pages/Support';
import './App.css';


function App() {
  return (
    <Router>
      <div className="wrapper">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/avizura" element={<Home />} />
            <Route path="/music" element={<Music />} />
            <Route path="/animations" element={<Animations />} />
            <Route path="/games" element={<Games />} />
            <Route path="/artwork" element={<Artwork />} />
            <Route path="/freebies" element={<Freebies />} />
            <Route path="/hire-me" element={<HireMe />} />
            <Route path="/support" element={<Support />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
