import React from 'react';
import './AnimationModal.css';
import TextFormatter from './TextFormatter';

function AnimationModal({ isOpen, activeVideo, closeModal }) {
    // const placeholderImage = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='180' height='180'><rect width='100%' height='100%' fill='%23ccc'/><text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='%23666' font-size='14' font-weight='bold'>No Image</text></svg>";

    if (!isOpen || !activeVideo) return null;

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content animation-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={closeModal}>X</button>
                <h3 className="modal-title">{activeVideo.title}</h3>
                <iframe
                    className="animation-modal-youtube-player"
                    src={activeVideo.videoUrl.replace("watch?v=", "embed/")}
                    title={activeVideo.title}
                    allowFullScreen
                ></iframe>
                <p className="modal-description"><TextFormatter text={activeVideo.description} /></p>
                {activeVideo.soundtrackLink && (
                    <p className="animation-modal-soundtrack-link">
                        <a href={activeVideo.soundtrackLink} target="_blank" rel="noopener noreferrer">
                            <img
                                src="https://img.icons8.com/ios-filled/50/000000/music.png"
                                alt="Soundtrack Icon"
                                style={{ width: '20px', marginRight: '5px' }}
                            />
                            Soundtrack
                        </a>
                    </p>
                )}
            </div>
        </div>
    );
}

export default AnimationModal;
