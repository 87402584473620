import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const navRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);


    return (
        <div className="full-navbar">
            <Link to="/" onClick={closeMenu}><h1 className="logo">Avizura</h1></Link>
            <nav className="navbar" ref={navRef}>

                <div className="menu-toggle" onClick={toggleMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>

                <div className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <ul>
                        <li><Link to="/music" onClick={closeMenu}>Music</Link></li>
                        <li><Link to="/animations" onClick={closeMenu}>Animations</Link></li>
                        <li><Link to="/games" onClick={closeMenu}>Games</Link></li>
                        <li><Link to="/artwork" onClick={closeMenu}>Artwork</Link></li>
                        <li><Link to="/freebies" onClick={closeMenu}>Royalty Free</Link></li>
                        <li><Link to="/hire-me" onClick={closeMenu}>Contact</Link></li>
                        <li><Link to="/support" onClick={closeMenu}>Support Me</Link></li>
                    </ul>
                </div>
            </nav>
        </div>

    );
}

export default Navbar;
