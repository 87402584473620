import './MusicModal.css';
import TextFormatter from './TextFormatter';

const MusicModal = ({ show, onClose, item }) => {
    if (!show || !item) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content music-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>X</button>
                <h2 className="modal-title">{item.title}</h2>
                <div className="modal-frame">
                    {item.embed ? (
                        <iframe
                            className="music-modal-player-embed"
                            src={item.embed}
                            seamless
                            title={item.title}
                        />
                    ) : (
                        <img
                            className="music-modal-player-image"
                            src={item.coverImage}
                            alt={`${item.title} cover`}
                        />
                    )}
                </div>
                <p className="modal-description music-modal-description"><TextFormatter text={item.description} /></p>
                {item.link ? (
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                        Download
                    </a>
                ) : (
                    <span>No download available</span>
                )}
            </div>
        </div>
    );
};

export default MusicModal;
