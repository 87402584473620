import React, { useState, useEffect } from 'react';
import artworkData from './../data/artworkData.json';
import './Artwork.css';
import ArtworkModal from './../components/ArtworkModal';

function Artwork() {
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (imageIndex) => {
        setActiveImageIndex(imageIndex);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
        return () => (document.body.style.overflow = 'unset');
    }, [isModalOpen]);

    const prevImage = () => {
        setActiveImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
    };

    const nextImage = () => {
        const section = artworkData.sections[activeSectionIndex];
        setActiveImageIndex((prev) => (prev < section.images.length - 1 ? prev + 1 : prev));
    };

    return (
        <div>
            <h2>Artwork</h2>
            {artworkData.sections.map((section, sectionIndex) => (
                <section key={sectionIndex}>
                    <div
                        className="section-header"
                        onClick={() => setActiveSectionIndex(activeSectionIndex === sectionIndex ? null : sectionIndex)}
                    >
                        <span className="section-title">{section.name}</span>
                    </div>
                    {activeSectionIndex === sectionIndex && (
                        <div className="grid">
                            {section.images.map((image, imageIndex) => (
                                <div
                                    key={imageIndex}
                                    className="artwork-image-thumbnail"
                                    onClick={() => openModal(imageIndex)}
                                >
                                    <img
                                        src={image.url}
                                        alt={image.title}
                                    />
                                    <h5 className="item-title art-item-title">{image.title}</h5>
                                </div>
                            ))}
                        </div>
                    )}
                </section>
            ))}

            {isModalOpen && (
                <ArtworkModal
                    activeImageIndex={activeImageIndex}
                    activeSectionIndex={activeSectionIndex}
                    artworkData={artworkData}
                    closeModal={closeModal}
                    prevImage={prevImage}
                    nextImage={nextImage}
                />
            )}
        </div>
    );
}

export default Artwork;
