import React from 'react';
import gameData from './../data/gameData.json';
import './Games.css';
import TextFormatter from '../components/TextFormatter';

function Games() {
    return (
        <div>
            <h2>Games</h2>
            <p>Games I have made over the years! Have fun, they can be quite challenging 😉</p>
            <div className="games-container">
                {gameData.map((game, index) => (
                    <div key={index} className="game-item">
                        <a href={game.link} target="_blank" rel="noopener noreferrer">
                            <img src={game.thumbnail} alt={game.title} className="game-thumbnail" />
                        </a>
                        <div className="game-text">
                            <h3>{game.title}</h3>
                            <p><TextFormatter text={game.description} /></p>
                            <div className="game-button-container">
                                <a href={game.link} target="_blank" rel="noopener noreferrer" className="play-button">
                                    PLAY
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Games;
