import React, { useEffect } from 'react';
import './ArtworkModal.css';
import TextFormatter from './TextFormatter';

function ArtworkModal({ activeImageIndex, activeSectionIndex, artworkData, closeModal, prevImage, nextImage }) {
    const currentImage = artworkData.sections[activeSectionIndex].images[activeImageIndex];
    const imagesInSection = artworkData.sections[activeSectionIndex].images;

    // Determine if the current image is the first or last in the section
    const isFirstImage = activeImageIndex === 0;
    const isLastImage = activeImageIndex === imagesInSection.length - 1;

    // Effect to handle keydown events
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && !isFirstImage) {
                prevImage();
            } else if (event.key === 'ArrowRight' && !isLastImage) {
                nextImage();
            } else if (event.key === 'Escape') {
                closeModal(); // Optional: Close the modal when the Escape key is pressed
            }
        };

        // Attach the event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFirstImage, isLastImage, prevImage, nextImage, closeModal]);

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content artwork-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={closeModal}>X</button>
                <div className="artwork-navigation">
                    {/* Conditionally render the left arrow */}
                    {!isFirstImage && (
                        <button className="artwork-arrow-button left" onClick={prevImage}>&lt;</button>
                    )}
                    {/* Conditionally render the right arrow */}
                    {!isLastImage && (
                        <button className="artwork-arrow-button right" onClick={nextImage}>&gt;</button>
                    )}
                </div>
                <h3 className="modal-title">{currentImage.title}</h3>
                <img
                    src={currentImage.url}
                    alt={currentImage.title}
                    className="artwork-modal-image"
                />
                <p className="modal-description"><TextFormatter text={currentImage.description} /></p>

                {currentImage.redbubbleImages && currentImage.redbubbleImages.length > 0 && (
                    <>
                        <h3>Get some merch!</h3>
                        <div className="redbubble-gallery">
                            {currentImage.redbubbleImages.map((rbImage, index) => (
                                <a
                                    href={rbImage.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                    className="redbubble-image-wrapper"
                                >
                                    <img
                                        src={rbImage.image}
                                        alt={`Redbubble item ${index + 1}`}
                                        className="redbubble-image"
                                    />
                                </a>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ArtworkModal;
