// ImageGallery.js
import React from 'react';
import PropTypes from 'prop-types';
import './ImageGallery.css';

const placeholderImage = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='180' height='180'><rect width='100%' height='100%' fill='%23ccc'/><text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='%23666' font-size='14' font-weight='bold'>No Image</text></svg>";

function ImageGallery({ images }) {
    return (
        <div className={`image-gallery`}>
            {images.map((image, index) => (
                <img
                    key={index}
                    src={image.path}
                    alt={image.alt}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = placeholderImage;
                    }}
                    className="gallery-image"
                />
            ))}
        </div>
    );
}

ImageGallery.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            alt: PropTypes.string,
        })
    ).isRequired
};

export default ImageGallery;
